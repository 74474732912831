// Body
html {
  @apply text-base;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
  &.nav-active {
    @apply overflow-hidden;
  }
  @apply text-gray-800
     text-optimize-legibility
     antialiased;
}

// Links

a {
  @apply transition;
}

button:disabled {
  opacity: 0.5;
}

// Typography

h1,
.h1 {
  @apply text-6xl;
  line-height: 1.05 !important;
  @include responsive($md) {
    @apply text-4xl;
  }
}

h2,
.h2,
h3,
.h3 {
  @apply text-4xl;
  line-height: 1.25 !important;
  @include responsive($sm) {
    @apply text-3xl;
  }
}

h4,
.h4 {
  @apply text-2xl;
  line-height: 1.25 !important;
  @include responsive($sm) {
    @apply text-xl;
  }
}

h5,
.h5 {
  @apply text-xl;
  line-height: 1.25 !important;
  @include responsive($sm) {
    @apply text-lg;
  }
}

.text-columns {
  h2,
  .h2 {
    @apply text-xl;
  }
}

p {
  font-weight: 300;
  @apply mb-4;
  &:empty {
    @apply hidden;
  }
  a {
    @apply underline;
  }
  strong {
    font-weight: 800;
  }
}

.formatted-text {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  p {
    @apply mb-4;
  }
  ul {
    @apply list-disc
      pl-10;
  }
}

// Selection

::selection {
  @apply text-gray-500
      bg-gray-100;
}

// FOUT fix

.wf-loading {
  @apply invisible;
}

.wf-active,
.wf-inactive {
  @apply visible;
}

// Layout

.header {
  position: relative;
  transition: 0.4s all ease;
  z-index: 999;
  &--nav {
    &-dropdown {
      &-menu {
        // display: none;
      }
      &:hover {
        .header--nav-dropdown-menu {
          cursor: pointer;
          // display: block;
        }
      }
    }
  }
  nav {
    a {
      position: relative;
      // &:hover {
      //    &:after {
      //       width: 100%;
      //    }
      // }
      // &:after {
      //    transition: all .25s ease;
      //    content: "";
      //    display: block;
      //    position: absolute;
      //    bottom: -.8rem;
      //    left: 0;
      //    right: 0;
      //    height: 2px;
      //    width: 0;
      // }
    }
  }
  &.scrolled {
    @apply opacity-0;
    transform: translateY(-100%);
  }
  &.nav-show {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    border-bottom: none;
    transform: translateY(0);
    @apply sticky
         text-white
         top-0
         opacity-100;
    svg {
      g {
        fill: white;
      }
    }
    .mobile-nav {
      &--trigger {
        .line {
          @apply text-white;
        }
      }
    }
  }
  &--dark {
    border-bottom: none;
    // nav {
    //    a {
    //       &:after {
    //          @apply bg-white;
    //       }
    //    }
    // }
    @apply text-white;
  }
  &--light {
    border-bottom: 1px solid var(--color-light-gray);
    // nav {
    //    a {
    //       &:after {
    //          @apply bg-black;
    //       }
    //    }
    // }
    svg {
      g {
        fill: black;
      }
    }
    .mobile-nav {
      &--trigger {
        .line {
          @apply text-black;
        }
      }
    }
    @apply bg-white
         text-black;
  }
  &--transparent {
    position: absolute;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    // nav {
    //    a {
    //       &:after {
    //          @apply bg-white;
    //       }
    //    }
    // }
    @apply text-white
         bg-transparent;
  }
}

.mobile-nav {
  transform: translateY(-100%);
  transition: all 0.4s ease;
  z-index: 9999;
  nav {
    a {
      @apply block;
    }
  }
  &--trigger {
    .line {
      @apply text-white;
      height: 3px;
    }
  }
}

.nav-active {
  .header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    svg {
      g {
        fill: #fff;
      }
    }
  }
  .mobile-nav {
    transform: translateY(0);
    &--trigger {
      .line {
        @apply text-white;
      }
    }
  }
}

.hero {
  position: relative;
  overflow: hidden;
  .img-overlay {
    z-index: -1;
  }
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 50% black overlay */
  z-index: 9;
}

.hero-container {
  position: relative;
  z-index: 1;
}

.footer {
  &-style1 {
    &--img {
      min-height: 400px;
      background-size: cover;
      @include responsive($md) {
        min-height: 200px;
      }
    }
  }
  button {
    margin-top: unset !important;
  }
}

.input-box {
  @apply mb-4;
  label {
    @apply mb-1;
  }
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-content {
  @apply bg-white
   rounded-md
   mx-auto;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-buttons {
  margin-top: 15px;
  text-align: right;
}

// Misc

.pagination {
  @apply flex
      items-center
      space-x-4;
}

.stripe-payment-elements-submit-button {
  @apply transition
   inline-block
   font-semibold
   items-center
   justify-between
   flex-shrink-0
   focus:outline-none
   focus:shadow-outline
   my-5
   text-sm px-5 py-2 rounded-full text-white hover:opacity-50;
}

/* Exit Modal Styles */
.exit-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.exit-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.exit-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.exit-modal-close:hover,
.exit-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

button {
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
