@tailwind base;
@tailwind components;

@import "utilities/rem";
@import "utilities/responsive";

@import "components/lazy-load";
@import "components/links";
@import "components/buttons";
@import "components/lists";
@import "components/blocks";
@import "components/hero-carousel.scss";
@import "components/payment.scss";
@import "components/register.scss";

@import "pages/home";

@import "base";

@tailwind utilities;
