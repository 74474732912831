#paymentForm-opayo-opayo-form {
  input {
    @apply mb-2
        pl-2
        border
        rounded
        py-2
        w-full
        lg:w-4/5;
  }
  legend {
    @apply pt-2;
  }
  .card-expiry,
  .card-cvc {
    @apply w-20;
  }
  button {
    @apply w-full
    pt-2
    lg:w-4/5;
    justify-content: center !important;
  }
}
#paymentForm {
  button {
    @apply w-full
        mt-2
        lg:w-4/5;
    justify-content: center !important;
  }
}
